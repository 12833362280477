<template>
    <div class="row justify-space-between">
        <div>
            <DxButton
                id="button_THCuaXeTheoThang_TaiLai"
                class="ma-1"
                :icon="$i18n.t('icon.reload')"
                @click="reload()"
                style="margin-left: 6px"
                :hint="$i18n.t('string.refresh')"
            />
            <DxButton
                id="button_THCuaXeTheoThang_XuatExCel"
                class="ma-1"
                :icon="$i18n.t('icon.excel')"
                @click="emitter.emit('excel')"
                style="margin-left: 6px"
                :hint="$i18n.t('string.excel')"
            />
            <DxButton
                id="button_THCuaXeTheoThang_In"
                class="ma-1"
                :icon="$i18n.t('icon.print')"
                @click="print()"
                style="margin-left: 6px"
                :hint="$i18n.t('string.print')"
            />
        </div>
        <div>
            <div class="row justify-end align-center">
                <span
                    v-if="!!selection_so"
                    class="pr-3"
                    style="white-space: nowrap"
                    >Sở GTVT:</span
                >
                <get-thong-tin-tinh-thanh-vue
                    v-if="!!selection_so"
                    id="dropdown_THCuaXeTheoThang_SoGiaoThong_Click_MultiSelects"
                    @result="getThongTinTinhThanh"
                    class="mr-2"
                />

                <span
                    v-if="!!selection_ben"
                    class="pr-3"
                    style="white-space: nowrap"
                    >Bến xe:</span
                >
                <get-thong-tin-ben-xe-vue
                    v-if="!!selection_ben"
                    @result="getThongTinBenXe"
                    :Query="data_props.SelectSo"
                    id="dropdown_THCuaXeTheoThang_BenXe_Click_MultiSelects"
                    class="mr-2"
                />
                <DxButton
                    id="button_THCuaXeTheoThang_CaiDatHienThi"
                    class="ma-1"
                    :icon="$i18n.t('icon.setting')"
                />
            </div>
        </div>
    </div>
</template>
<script>
import moment from "moment";
import GetThongTinTinhThanhVue from "../Get/GetThongTinTinhThanh.vue";
import GetThongTinBenXeVue from "../Get/GetThongTinBenXe.vue";
import { DxButton } from "devextreme-vue";
import $ from "jquery";
export default {
    props: {
        Params: { type: Object, default: {} },
    },
    components: {
        GetThongTinTinhThanhVue,
        GetThongTinBenXeVue,
        DxButton,
    },
    data() {
        return {
            check_load: 0,
            data_props: {
                SelectSo: 0,
                SelectBenXe: 0,
                SelectDVVT: 0,
                Key: null,
            },
        };
    },
    computed: {
        selection_so() {
            if (
                !!this.Params &&
                this.Params.Type == this.$i18n.t("string.tongcuc")
            ) {
                return true;
            }
            return false;
        },
        selection_ben() {
            if (
                !!this.Params &&
                (this.Params.Type == this.$i18n.t("string.tongcuc") ||
                    this.Params.Type == this.$i18n.t("string.so"))
                // this.Params.Type == this.$i18n.t("string.benxe"))
            ) {
                return true;
            }
            return false;
        },
    },
    methods: {
        onContentReady(e) {
            var html = e.component.content();
            $(html).css("padding", "8px");
        },

        print() {
            window.print();
        },
        reload() {
            this.$emit("reload");
            this.emitter.emit("reload");
        },
        getThongTinTinhThanh(data) {
            this.data_props.SelectSo = data.Model;
            this.$emit("selectionChange", this.data_props);
        },
        getThongTinBenXe(data) {
            this.data_props.SelectBenXe = data.Model;
            this.$emit("selectionChange", this.data_props);
        },
    },
};
</script>