<template>
    <DxDataGrid
        class="pt-2 table-page"
        id="table_TongHopCuaXeTheoThang_DanhSachBaoCao"
        ref="table_TongHopCuaXeTheoThang_DanhSachBaoCao"
        :show-borders="true"
        :data-source="Params.DataTable"
        :remote-operations="true"
        :allow-column-resizing="true"
        :column-auto-width="false"
        @cellHoverChanged="onCellHoverChanged"
        key-expr="IdBenXe"
        height="calc(100vh - 170px)"
        :loadPanel="{ showIndicator: false, showPane: false, text: '' }"
    >
        <DxPaging :page-size="parseInt($i18n.t('Settings.PageSize'))" />
        <!-- <DxGrouping :auto-expand-all="false"/> -->
        <DxScrolling
            :mode="$i18n.t('Settings.DxScrolling_Mode')"
            :row-rendering-mode="
                $i18n.t('Settings.DxScrolling_RowRenderingMode')
            "
        />
        <DxColumnFixing :enabled="true" />
        <DxHeaderFilter :visible="true" />
        <slot name="dx-column"></slot>
        <DxColumn
            :allowFiltering="false"
            :fixed="true"
            alignment="left"
            data-field="BienKiemSoat"
            :minWidth="300"
            caption="Biển kiểm soát"
        ></DxColumn>
        <DxColumn
            :allowFiltering="false"
            alignment="left"
            data-field="TenDoanhNghiep"
            caption="Đơn vị vận tải"
            :minWidth="300"
        ></DxColumn>
        <DxColumn
            :allowFiltering="false"
            alignment="left"
            data-field="TenTuyen"
            :minWidth="300"
            caption="Tuyến vận chuyển"
        ></DxColumn>
        <DxColumn caption="Số chuyến" alignment="center" :minWidth="300">
            <DxColumn
                :allowFiltering="false"
                alignment="left"
                data-field="SoChuyenDaChay"
                caption="Thực hiện (1)"
                width="100"
                css-class="datagrid-header-white-space"
            ></DxColumn>
            <DxColumn
                :allowFiltering="false"
                alignment="left"
                data-field="SoChuyenNghi"
                caption="Số chuyến nghỉ (2)"
                width="120"
                css-class="datagrid-header-white-space"
            ></DxColumn>
            <DxColumn
                :allowFiltering="false"
                alignment="left"
                data-field="SoChuyenDangKy"
                caption="Dự kiến (3)"
                width="90"
                css-class="datagrid-header-white-space"
            ></DxColumn>
        </DxColumn>
        <DxColumn
            :minWidth="300"
            :allowFiltering="true"
            alignment="left"
            data-field="PhanTramThucHien"
            cell-template="status-cell"
            caption="Tỉ lệ(%) = ((1) / (3))%"
        >
            <DxHeaderFilter :data-source="Params.DataRatio" />
        </DxColumn>

        <!-- Hover button -->
        <DxColumn
            cssClass="column-button-hover"
            data-field="Detail"
            caption="Trạng thái"
            width="1"
            cell-template="buttons-cell"
        ></DxColumn>

        <template #buttons-cell="{ data }">
            <div class="buttons">
                <DxButton
                    id
                    icon="mdi mdi-eye"
                    @click="XemChiTiet(data)"
                    v-if="
                        $Helper.Common.CheckRole(
                            $i18n.t('Quyen.BaoCaoBenXe'),
                            $i18n.t('Quyen.ChiTietBaoCaoHoatDongCuaXe')
                        )
                    "
                ></DxButton>
            </div>
        </template>
        <template #color-cells="{ data }">
            <p :style="'color:' + data.color">{{ data.text }}</p>
        </template>
        <template #status-cell="{ data }">
            <div
                :style="
                    data.data.PhanTramThucHien > 70
                        ? 'color:#13B000'
                        : 'color:#D10909'
                "
            >
                {{ data.data.PhanTramThucHien }}
            </div>
        </template>
    </DxDataGrid>
</template>
<script>
import { DxDataGrid, DxButton } from "devextreme-vue";
import {
    DxColumn,
    DxPaging,
    DxScrolling,
    DxColumnFixing,
    DxHeaderFilter,
} from "devextreme-vue/data-grid";

export default {
    props: {
        Params: { type: Object, default: {} },
    },
    data() {
        return {};
    },
    components: {
        DxDataGrid,
        DxPaging,
        DxScrolling,
        DxColumnFixing,
        DxHeaderFilter,
        DxColumn,
        DxDataGrid,
        DxButton,
    },
    methods: {
        XemChiTiet(data) {
            localStorage.setItem("BienKiemSoat", data.data.IdXe);
            localStorage.setItem("NgayHoatDong", new Date());
            window.open(
                "/Bao-Cao-Thong-Ke/Bao-Cao-Hoat-Dong-Cua-Xe/Chi-Tiet",
                "_blank"
            );
        },
        onCellHoverChanged(e) {
            this.$Helper.Common.table({ Element: e, Buttons: true }).HoverRow();
        },
    },
    mounted() {
        let self = this;
        this.emitter.on("excel", function () {
            let init = {
                Element:
                    self.$refs.table_TongHopCuaXeTheoThang_DanhSachBaoCao
                        .instance,
                WorkSheet: "Danh Sách",
                FileName: "Bao-Cao-Tong-Hop-Cua-Xe-Theo-Thang",
            };
            self.$Helper.Common.excel(init).Export();
        });
        this.emitter.on("reload", function () {
            let datagrid =
                self.$refs.table_TongHopCuaXeTheoThang_DanhSachBaoCao.instance;
            datagrid.clearFilter();
        });
    },
    beforeUnmount() {
        this.emitter.off("excel");
    },
};
</script>
<style scoped>
>>> .dx-datagrid-rowsview
    .dx-row.dx-data-row.dx-row-lines
    > td[aria-colindex="6"],
>>> .dx-datagrid-rowsview
    .dx-row.dx-data-row.dx-row-lines
    > td[aria-colindex="7"],
>>> .dx-datagrid-rowsview
    .dx-row.dx-data-row.dx-row-lines
    > td[aria-colindex="8"],
>>> .dx-datagrid-rowsview
    .dx-row.dx-data-row.dx-row-lines
    > td[aria-colindex="9"] {
    text-align: right !important;
}
>>> .dx-datagrid-headers .dx-datagrid-table .dx-row > td {
    font-size: 12px !important;
}
</style>