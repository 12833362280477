<template>
    <div>
        <DxToolbar :height="56" class="toolbar">
            <DxItem location="before" locate-in-menu="never">
                <template #default>
                    <router :breadcrumbsItems="breadcrumbsItems" />
                </template>
            </DxItem>
            <DxItem location="after">
                <template #default>
                    <search-vue
                        @search="searchs"
                        @model="modelSearch"
                        id="textbox_THCuaXeTheoThang_Timkiem"
                    />
                </template>
            </DxItem>
        </DxToolbar>
        <div class="pl-3">
            <DxValidationGroup ref="formValidation">
                <div
                    class="row align-center pt-2 pb-4"
                    style="border-bottom: 1px solid #ddd"
                >
                    <span class="mr-2 font-16" id="label_THCuaXeTheoThang_Tu"
                        >Chọn tháng</span
                    >
                    <DxDateBox
                        v-model="model.DateFrom"
                        type="date"
                        displayFormat="MM/yyyy"
                        dropDownButtonTemplate="customIcon"
                        validationMessageMode="always"
                        id="datebox_THCuaXeTheoThang_TuNgay"
                        class="datebox-baocao"
                        stylingMode="underlined"
                        :useMaskBehavior="true"
                    >
                        <DxCalendarOptions
                            max-zoom-level="year"
                            min-zoom-level="decade"
                            zoom-level="year"
                        />
                        <DxValidator>
                            <DxRequiredRule message="Không được để trống!" />
                            <DxRangeRule
                                :max="maxDate"
                                :message="$i18n.t('DefaultString.MaxDate')"
                            />
                            <DxRangeRule
                                :min="minDate"
                                :message="$i18n.t('DefaultString.MinDate')"
                            />
                        </DxValidator>
                        <template #customIcon="{}">
                            <DxButton icon="mdi mdi-calendar" />
                        </template>
                    </DxDateBox>
                    <DxButton
                        class="ml-3"
                        :icon="$i18n.t('icon.search')"
                        text="Lọc"
                        styling-mode="outlined"
                        :rtlEnabled="true"
                        id="button_THCuaXeTheoThang_Loc"
                        @click="validate"
                    />
                </div>
            </DxValidationGroup>
            <toolbar-vue
                :Params="data_from_api"
                @selectionChange="selectionChange"
                @reload="reloadFuc"
            />
            <datatable-vue :Params="data_from_api" :Reload="reload" />
        </div>
    </div>
</template>
<script>
import moment from "moment";
import router from "@/components/_Common/Components/router";
import ToolbarVue from "@/components/BaoCaoThongKe/TongHopCuaXeTheoThang/Toolbar";
import searchVue from "@/components/_Common/Components/Search";
import DatatableVue from "@/components/BaoCaoThongKe/TongHopCuaXeTheoThang/Datatable";
import DxToolbar, { DxItem } from "devextreme-vue/toolbar";
import { DxDateBox, DxButton, DxValidator } from "devextreme-vue";
import { DxRangeRule, DxRequiredRule } from "devextreme-vue/data-grid";
import { DxValidationGroup } from "devextreme-vue/validation-group";
import { DxCalendarOptions } from "devextreme-vue/date-box";
export default {
    // Permission: $PermText("DanhSach", "HoatDongXeTheoThang"),
    layout: "application",
    components: {
        router,
        ToolbarVue,
        DatatableVue,
        searchVue,
        DxToolbar,
        DxItem,
        DxValidationGroup,
        DxDateBox,
        DxValidator,
        DxRequiredRule,
        DxRangeRule,
        DxButton,
        DxCalendarOptions,
    },
    data() {
        return {
            url: this.$i18n.t("url.HoatDongBenXeTheoThangList"),
            url_capdo: this.$i18n.t("url.CapDoTaiKhoan"),
            id_table: "IdBenXe",
            maxDate: new Date(this.$i18n.t("DefaultNumBer.MaxDate")),
            minDate: new Date(this.$i18n.t("DefaultNumBer.MinDate")),
            model: {
                DateFrom: new Date(
                    new Date().getMonth() +
                        1 +
                        "/01/" +
                        new Date().getFullYear()
                ),
                Search: "",
                IdSos: 0,
                IdBens: 0,
            },
            btn: [
                {
                    name: "password",
                    location: "after",
                    options: {
                        icon: "mdi mdi-calendar",
                        type: "normal",
                        onClick: () => {},
                    },
                },
            ],
            data_from_api: {
                Type: null,
                DataSelect: {
                    SelectSo: [],
                    SelectBenXe: [],
                    SelectDVVT: [],
                },
                DataTable: [],
                DataRatio: [],
            },
            reload: 0,
            breadcrumbsItems: [
                {
                    id: "breadcrums_BCHoatDongXe_BaoCaoThongKe",
                    text: this.$i18n.t("stringRouter.nodeBCTK"),
                    disabled: true,
                    to: "",
                },
                {
                    id: "breadcrums_BCHoatDongXe_BaoCaoHoatDongXe",
                    text: this.$i18n.t("stringRouter.brancheTHCXTT"),
                    disabled: true,
                    to: "",
                },
            ],
        };
    },
    methods: {
        async getData(load_capdo, checkGetData) {
            try {
                let query = {
                    thang: new Date(this.model.DateFrom).getMonth() + 1,
                    nam: new Date(this.model.DateFrom).getFullYear(),
                    idSos: this.model.IdSos,
                    idBens: this.model.IdBens,
                    txtTimKiem: this.model.Search || "",
                };
                this.data_from_api.DataTable = (
                    await this.$store.dispatch(
                        "BaoCaoThongKe/Get_TongHopCuaXeTheoThang",
                        query
                    )
                ).Store;
            } catch (error) {
                console.log(error);
            } finally {
            }
        },

        async getDataRatio() {
            this.data_from_api.DataRatio = [
                {
                    text: ">70%",
                    value: ["PhanTramThucHien", ">", "70"],
                    template: "color-cells",
                    color: "#13B000",
                },
                {
                    text: "<=70%",
                    value: ["PhanTramThucHien", "<=", "70"],
                    template: "color-cells",
                    color: "#D10909",
                },
            ];
        },
        reloadFuc() {
            this.model = {
                DateFrom: new Date(),
                DateTo: new Date(),
                Search: "",
                IdSos: 0,
                IdBens: 0,
            };
            this.getData();
        },
        searchs(search) {
            this.getData();
        },
        modelSearch(search) {
            this.model.Search = search;
        },
        selectionChange(data) {
            this.model.IdBens = data.SelectBenXe;
            this.model.IdDvvts = data.SelectDVVT;
            this.model.IdSos = data.SelectSo;
        },
        validate(e) {
            this.getData();
        },
    },
    watch: {},
    created() {
        this.data_from_api.Type =
            this.$Core.Auth.TokenParsed?.CauHinhCu?.LoaiDonVi;
    },
    mounted() {
        this.getDataRatio();
        // this.getData();
    },
};
</script>
<style>
.datebox-baocao .dx-dropdowneditor-button {
    width: 100%;
}
.datebox-baocao
    .dx-datebox.dx-auto-width.dx-dropdowneditor-button-visible.dx-editor-underlined
    .dx-texteditor-input,
.dx-datebox:not(.dx-texteditor-empty).dx-auto-width.dx-dropdowneditor-button-visible.dx-editor-underlined
    .dx-texteditor-input {
    padding-right: unset;
    max-width: 150px;
}
</style>
<style scoped>
>>> #btn-filter.dx-button .dx-button-content {
    flex-direction: row-reverse;
    display: flex;
}
>>> #btn-filter.dx-button.dx-button-has-icon.dx-button-has-text .dx-icon {
    margin-left: 8px;
    margin-right: 0px;
    line-height: 22px;
    font-size: 21px;
}
</style>